import React, { useMemo } from "react";
import { View, Text, ScrollView } from "react-native-web";
import ArrowButton from "./ArrowButton";
import PageButton from "./PageButton";

const PageButtonNavigateV2 = (props) => {
  const { currentPage, totalCount, limitItemsPerPage, onSelectPage } = props;
  const pagesCount = Math.ceil(totalCount / limitItemsPerPage);

  const pageButtons = useMemo(() => {
    let pages = [];
    let startPage, endPage;
    if (pagesCount === 1) {
      // แสดงเพียงหน้าเดียว
      pages.push(
        <PageButton
          key="pageButton_1"
          label="1"
          isSelect={currentPage === 1}
          onClick={() => {
            if(currentPage === 1){
              return;
            }
            onSelectPage(1)
          }}
        />
      );
      return pages; // ส่งกลับเนื่องจากมีเพียงหน้าเดียว
    }
  
    // แสดงหน้าแรก
    pages.push(
      <PageButton
        key="pageButton_1"
        label="1"
        isSelect={currentPage === 1}
        onClick={() => {
          if(currentPage === 1){
            return;
          }
          onSelectPage(1)
        }}
      />
    );
  
    // แสดง "..." หากหน้าปัจจุบันมากกว่า 4
    if (currentPage > 4) {
      pages.push(<Text key="dots_left" style={Styles.spaceDot}>...</Text>);
    }
  
    // กำหนดหน้าเริ่มต้นและหน้าสุดท้ายที่จะแสดง
    if (currentPage < 5) {
      startPage = 2;
      endPage = Math.min(5, pagesCount - 1);
    } else {
      startPage = currentPage - 1;
      endPage = Math.min(currentPage + 1, pagesCount - 1);
    }
  
    // แสดงหน้าที่เลือกและหน้าข้างเคียง
    for (let x = startPage; x <= endPage; x++) {
      pages.push(
        <PageButton
          key={"pageButton_" + x}
          label={x.toLocaleString()}
          isSelect={currentPage === x}
          onClick={() => {
            if(x === currentPage){return}
            onSelectPage(x)
          }}
        />
      );
    }
  
    // แสดง "..." หากมีหน้าเหลือมากกว่าหน้าที่แสดง
    if (currentPage + 1 < pagesCount - 1) {
      pages.push(<Text key="dots_right" style={Styles.spaceDot}>...</Text>);
    }
  
    // แสดงหน้าสุดท้ายหากไม่ได้อยู่ในรายการที่แสดงอยู่แล้ว
    if (endPage < pagesCount) {
      pages.push(
        <PageButton
          key={"pageButton_" + pagesCount}
          label={pagesCount.toLocaleString()}
          isSelect={currentPage === pagesCount}
          onClick={() => {
            if(pagesCount === currentPage){return;}
            onSelectPage(pagesCount)
          }}
        />
      );
    }
  
    return pages;
  }, [currentPage, pagesCount]); // Recalculate only if currentPage or pagesCount changes

  return (
    <View style={Styles.container}>
      <Text style={{ color: "rgba(105, 109, 121, 1)" }}>
        {`รายการ ${((currentPage - 1) * limitItemsPerPage) + 1}-${Math.min(currentPage * limitItemsPerPage, totalCount)} จาก ${totalCount}`}
      </Text>
      <View style={{ width: 40 }} />

      <ArrowButton
        size={20}
        color={currentPage - 1 === 0 ? "#8A91A1" : "#AA4942"}
        onPress={() => {
          if (currentPage - 1 > 0) {
            onSelectPage && onSelectPage(currentPage - 1);
          }
        }}
      />
      <View style={{ width: 12 }} />
      <View>
        <ScrollView
          horizontal
          style={{
            flex:1
          }}
          contentContainerStyle={{
            alignItems: "center",
          }}
        >
          {pageButtons} 
      </ScrollView>
      </View>
      <View style={{ width: 12 }} />
      <ArrowButton
        direction={"right"}
        size={20}
        color={currentPage + 1 > pagesCount ? "#8A91A1" : "#AA4942"}
        onPress={() => {
          if (currentPage + 1 <= pagesCount) {
            onSelectPage && onSelectPage(currentPage + 1);
          }
        }}
      />
    </View>
  );
};

const Styles = {
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 10,
  },
  spaceDot:{
    marginRight:9,
    marginLeft:3
  }
};

export default PageButtonNavigateV2;
