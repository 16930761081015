import languageJson from './languageList.json';
import languageSelectList from './languageSelectList.json';
let language = {
}

language.getSelectList = function(){
    return languageSelectList;
}

language.getWord = function(word,code){
    return Object(languageJson.filter(d => d.WORD === word)).length > 0 ? languageJson.filter(d => d.WORD === word)[0][code] : "-"
}

export default language;

