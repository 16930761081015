import React, { useEffect, useState } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import SearchBox from "../../components/textField/SearchBox";
import PopupAddCustomer from "./component/PopupAddCustomer";
//images
import CustomerInfoTable from "./component/CustomerInfoTable";
//function
import { downloadAllCustomerSales } from "../../services/api/sales/sales";
import { useSelector } from "react-redux";

const ShoppingCustomer = (props) => {
  const navigate = useNavigate();
  const {user} = useSelector((state)=>{return state});
  const [customerSalesList, setCustomerSalesList] = useState([]);
  const [editData, setEditData] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
 

  useEffect(() => {
    downloadAllData();
  }, []);


  const downloadAllData = async () => {
    let countrys = await downloadAllCustomerSales();
    let initCustomerSales = [];
    console.log(countrys);
    countrys.forEach((item) => {
      initCustomerSales.push(item);
    });
    setCustomerSalesList(initCustomerSales);
  };

  const optionHandle = (value)=>{
    const {action} = value;
    if(action === "edit" ){
      if(!user.agentCode){
        alert("ไม่พบรหัสตัวแทน");
        return;
      }
      setEditData(value);
      setOpenPopup(true);
    }
  }

  return (
    <React.Fragment>
    <PopupAddCustomer
        visible={openPopup}
        saleCode={user.agentCode}
        editData={editData}
        onClose={() => {
          downloadAllData();
          setEditData(null);
          setOpenPopup(false);
        }}
    />

    <View style={Styles.container}>
      <Grid container style={Styles.topContainer} spacing={2}>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <View style={{ flex: 1 }}>
            <Text style={Styles.textTopContainer1}>รายการข้อมูลลูกค้า</Text>
            <Text style={Styles.textTopContainer2}>
              ขายปลีกและลูกค้า / รายการข้อมูลลูกค้า
            </Text>
          </View>
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <Button
            buttonText={"เพิ่มรายการขายปลีก"}
            color={"primary"}
            style={{ width: "100%" }}
            styleText={Styles.topButtonText}
            onClick={() => {
              navigate("/shoppingAdd");
            }}
          />
        </Grid>
      </Grid>
      <View style={{ height: 30 }} />

      <View style={Styles.contentContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <View style={{ flex: 1 }}>
              <SearchBox placeholder={"ค้นหารายการ"} />
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button
              buttonText={"ค้นหา"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              onClick={() => {}}
            />
          </Grid>
        </Grid>
        <Divider />

        <View style={{ flex: 1 }}>
          <CustomerInfoTable 
          tableData={customerSalesList}
          onSelect={(values)=>{
            optionHandle(values)
          }}
           />
        </View>
      </View>
    </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};

export default ShoppingCustomer;
