import moment from "moment";

export const month = [
  { value: 1, label: "มกราคม" },
  { value: 2, label: "กุมภาพันธ์" },
  { value: 3, label: "มีนาคม" },
  { value: 4, label: "เมษายน" },
  { value: 5, label: "พฤษภาคม" },
  { value: 6, label: "มิถุนายน" },
  { value: 7, label: "กรกฏาคม" },
  { value: 8, label: "สิงหาคม" },
  { value: 9, label: "กันยายน" },
  { value: 10, label: "ตุลาคม" },
  { value: 11, label: "พฤศจิกายน" },
  { value: 12, label: "ธันวาคม" },
];

export function exportDayOfMonthArray(currentDate) {
  let currentMonth = currentDate.getMonth();
  let currentYear = currentDate.getFullYear();
  let numDays = new Date(currentYear, currentMonth + 1, 0).getDate();

  let dayOfMonthArray = [];
  for (let day = 1; day <= numDays; day++) {
    dayOfMonthArray.push(day);
  }

  return dayOfMonthArray;
}

export const getMonth = () => {
  let initMonth = [];
  let indexMonth = 1;
  months.forEach((element) => {
    initMonth.push({ value: indexMonth, label: element.TH });
    indexMonth += 1;
  });
  return initMonth;
};

export const months = [
  { EN: "January", TH: "มกราคม" },
  { EN: "February", TH: "กุมภาพันธ์" },
  { EN: "March", TH: "มีนาคม" },
  { EN: "April", TH: "เมษายน" },
  { EN: "May", TH: "พฤษภาคม" },
  { EN: "June", TH: "มิถุนายน" },
  { EN: "July", TH: "กรกฏาคม" },
  { EN: "August", TH: "สิงหาคม" },
  { EN: "September", TH: "กันยายน" },
  { EN: "October", TH: "ตุลาคม" },
  { EN: "November", TH: "พฤศจิกายน" },
  { EN: "December", TH: "ธันวาคม" },
];

export const year = () => {
  let years = [];
  const dateStart = moment().add(-100, "y");
  const dateEnd = moment();
  while (dateEnd.diff(dateStart, "years") >= 0) {
    years.push({
      value: Number(dateStart.format("YYYY")),
      label: Number(dateStart.format("YYYY")),
    });
    dateStart.add(1, "year");
  }
  return years;
};
