import React, { useState, useEffect } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
//component
import Button from "../../components/button/Button";
import SearchBox from "../../components/textField/SearchBox";
import Dropdown from "../../components/dropdown/Dropdown";
import PopupShoppingDetail from "./component/PopupShoppingDetail";
//images
import ShoppingMyAgentTable from "./component/ShoppingMyAgentTable";
import { useNavigate } from "react-router-dom";
import { useDialog } from "../../components/modal/Popup";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";
import moment from "moment-timezone";
import DatePickerRange from "../../components/calendar/DatePickerRange";

const ShoppingMyAgent = (props) => {
 
  const navigate = useNavigate();
  const dialog = useDialog();
  //state control
  const [pageControl,setPageControl] = useState({
    currentPage:1,
    limitItemsPerPage:20
  });
  const [shoppingMyAgent, setShoppingMyAgent] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  //view order
  const [popupDetail, setPopupDetail] = useState(false);
  const [viewOrder, setViewOrder] = useState();

  const [search, setSearch] = useState({
    startDate: null,
    endDate: null,
    wording: "",
    status: "",
    currentPage:1
  });
  const [prepareSearch, setPrepareSearch] = useState({
    startDate: null,
    endDate: null,
    wording: "",
    status: "",
  });
  const [optionShow, setOptionShow] = useState([
    { value: "", label: "ทั้งหมด" },
    { value: "Y", label: "สำเร็จ" },
    { value: "N", label: "ไม่สำเร็จ" },
  ]);

  useEffect(()=>{
      searchFilter(1);
  },[]);

  useEffect(()=>{
    searchFilter(search.currentPage);
  },[search]);


  const searchFilter = async (currentPage = 1) => {
    dialog.loading();
    let url = apiPath.retail.getRetailMyAgentV2;
    let { status, response } = await get(
      url,
      {
        startDate:search.startDate ? moment.utc(search.startDate).set({ h: 0, m: 0, s: 0 }) : null,
        endDate: search.endDate ? moment.utc(search.endDate).set({ h: 23, m: 59, s: 59 }) : null,
        wording: search.wording,
        limit:Number(pageControl.limitItemsPerPage),
        offset:(Number(currentPage) - 1) * Number(pageControl.limitItemsPerPage)
      },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      await setShoppingMyAgent(response.data.data);
      setTotalCount(response.data.totalCount);
      dialog.cancle();
    }
  };

  return (
    <React.Fragment>
      <PopupShoppingDetail
        visible={popupDetail}
        data={viewOrder}
        onEnter={() => {}}
        onClose={() => {
          setPopupDetail(false);
        }}
      />
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>
                รายการขายปลีกของตัวแทน
              </Text>
              <Text style={Styles.textTopContainer2}>
                ขายปลีกและลูกค้า / รายการขายปลีกของตัวแทน
              </Text>
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button
              buttonText={"เพิ่มรายการขายปลีก"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              onClick={() => {
                navigate("/shoppingAdd");
              }}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />

        <View style={Styles.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} style={{ zIndex: 999999 }}>
              <DatePickerRange
                startDate={prepareSearch.startDate}
                endDate={prepareSearch.endDate}
                placeholder={"เลือกวันที่เริ่มต้น - วันที่สิ้นสุด"}
                isTop={false}
                clearButton={true}
                styles={{ width: "100%" }}
                onClear={()=>{
                  setPrepareSearch({
                    ...prepareSearch,
                    startDate: null,
                    endDate: null,
                  });
                }}
                onChange={(values) => {
                  const { startDate, endDate } = values;
                  if (startDate && endDate) {
                    setPrepareSearch({
                      ...prepareSearch,
                      startDate: startDate.utc(),
                      endDate: endDate.utc(),
                    });
                  }
                }}
              />{" "}
            </Grid>
            <Grid item xs={12} md={5}>
              <SearchBox
                value={prepareSearch.wording}
                placeholder={"ค้นหารายการ"}
                onChangeText={(text) => {
                  setPrepareSearch({ ...prepareSearch, wording: text });
                }}
              />{" "}
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                buttonText={"ค้นหา"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {
                  setSearch({
                    ...search,
                    startDate: prepareSearch.startDate,
                    endDate: prepareSearch.endDate,
                    wording: prepareSearch.wording,
                    currentPage:1
                  })
                  setPageControl({
                    ...pageControl,
                    currentPage:1,
                  });   
                }}
              />
            </Grid>
          </Grid>
          <View
            style={{
              width: "100%",
              height: 1,
              backgroundColor: "#EDEEF0",
              marginTop: 24,
              marginBottom: 24,
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Text style={Styles.titleText}>แสดงผล</Text>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={""}
                list={optionShow}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value);
                  setSearch({ ...search, status: value });
                }}
              />
            </Grid>
          </Grid>
          <View style={{ flex: 1 }}>
            <ShoppingMyAgentTable
              tableData={
                shoppingMyAgent.length > 0
                  ? shoppingMyAgent
                  : []
              }
              totalCount={totalCount}
              pageControl={pageControl}
              onSelect={(values) => {
                setViewOrder(values);
                setPopupDetail(true);
              }}
              onSelectPage={(values)=>{
                setPageControl({
                  ...pageControl,
                  ...values
                });
                searchFilter(values.currentPage);
              }}
            />
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};

export default ShoppingMyAgent;
