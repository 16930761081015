import React from "react";
import { TouchableOpacity } from "react-native-web";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const ArrowButton = (props) => {
  const { direction = "left", color = "#000", size = 25 } = props;
  const { onPress } = props;
  return (
    <TouchableOpacity
      onPress={() => {
        onPress && onPress();
      }}
    >
      {direction === "left" ? (
        <IoIosArrowBack style={{ fontSize: size, color: color }} />
      ) : (
        <IoIosArrowForward style={{ fontSize: size, color: color }} />
      )}
    </TouchableOpacity>
  );
};

export default ArrowButton;
